import React, { useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useMount } from 'react-use';
import { Add, CheckCircle, Error } from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import { LocalizationTypography } from './localization';

import { INPUT_BORDER_STYLES, TRACKING_BUTTON } from '~common';
import { MainButton } from '~components/atoms';
import {
  useMutationRequestBattery,
  useNavigateInApp,
  useRedeemReward,
} from '~hooks';
import {
  useEventTrackingContext,
  useLanguageContext,
  useMainContext,
} from '~providers';
import { formatNumber, handleOpenApp } from '~utils';

export const WelcomeBack = () => {
  const { lt } = useLanguageContext();

  const { recordBtnClicked } = useEventTrackingContext();
  const {
    handleEditPaymentMethod,
    cachedPhoneNumber,
    authToken,
    paymentInfo,
    initialComplete,
    rentalQuery,
    saveRentalId,
    preAuthAmount,
  } = useMainContext();

  const {
    onChangePromoteCode,
    redeemReward,
    isRedeemRewardLoading,
    promoteCode,
    rewardRedeemError,
    rewardRedeemSuccessMessage,
  } = useRedeemReward();

  const [searchParams] = useSearchParams();
  const [isFetchingRental, setIsFetchingRental] = useState(false);
  const requestBatteryMutation = useMutationRequestBattery();

  const navigate = useNavigateInApp();

  useMount(() => {
    if (!authToken || !searchParams.get('box')) {
      navigate('/fuzebox');
    }
    if (!paymentInfo?.paymentMethodId) {
      navigate('/fuzebox');
    }
  });

  const handleEjectBattery = async (
    event: React.SyntheticEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
    try {
      const stationSerialNumber = searchParams.get('box');

      if (paymentInfo && stationSerialNumber) {
        const requestBattery = {
          paymentMethod: {
            id: paymentInfo.paymentMethodId,
            method: paymentInfo.method,
          },
          stationSerialNumber,
        };
        requestBatteryMutation.mutate(requestBattery, {
          onSuccess: (res) => {
            setIsFetchingRental(true);
            //delay 5s then refetch
            setTimeout(() => {
              saveRentalId(res.data?.rentalId);
              setIsFetchingRental(false);
            }, 5000);
          },
        });
      }
    } catch (err) {
      toast.error((err as Error).message);
    }

    recordBtnClicked(TRACKING_BUTTON.EJECT_BATTERY, {
      paymentMethod: paymentInfo?.method,
    });
  };

  const onClickViewTerms = () => {
    window?.open(
      process.env.REACT_APP_VIEW_TERMS_URL ??
        'https://www.chargefuze.com/terms-of-service/',
      '_blank',
    );
  };

  const renderEndAdornmentPromotionField = useMemo(() => {
    if (rewardRedeemError) {
      return <Error color="error" fontSize="small" />;
    }
    if (rewardRedeemSuccessMessage) {
      return <CheckCircle color="success" fontSize="small" />;
    }
    return null;
  }, [rewardRedeemError, rewardRedeemSuccessMessage]);

  if (!initialComplete) {
    return (
      <Stack
        flex={1}
        justifyContent={'center'}
        alignItems="center"
        height={'calc(100vh - 170px)'}
      >
        <CircularProgress />
      </Stack>
    );
  }

  return (
    <Stack display="grid" sx={{ gridRowGap: 32 }} gap={4} p={3}>
      <Stack display="grid" sx={{ gridRowGap: 16 }}>
        <LocalizationTypography
          variant="h4"
          fontWeight={600}
          textId="app.rentalPage.welcomeBack.header"
          defaultText="Welcome back!"
        />
        <Stack direction={'row'}>
          <LocalizationTypography
            disableParentTypography
            textId="app.rentalPage.welcomeBack.text.readyToRentCharger"
            components={{
              tag1: (
                <Typography
                  variant="h6"
                  fontWeight={600}
                  fontSize={'1.25rem'}
                />
              ),
              tag2: (
                <Typography
                  sx={{ marginLeft: '0.5rem' }}
                  variant="h6"
                  fontWeight={600}
                  fontSize={'1.25rem'}
                  color="primary.main"
                />
              ),
            }}
            defaultText="<tag1>You’re ready to</tag1> <tag2>rent a charger.</tag2>"
          />
        </Stack>
        <Stack display="grid" sx={{ gridRowGap: 4 }}>
          <LocalizationTypography
            variant="body1"
            fontWeight={400}
            color="#6A737D"
            textId="app.rentalPage.welcomeBack.text.confirmOrEdit"
            defaultText="Confirm or edit your details below."
          />
          <Stack direction="row" alignItems="center">
            <LocalizationTypography
              variant="body1"
              fontWeight={400}
              color="#6A737D"
              textId="app.rentalPage.welcomeBack.text.cardBePreAuthorized"
              textValues={{ value: preAuthAmount }}
              defaultText={`Your card will be pre-authorized for ${preAuthAmount}.`}
            />

            <IconButton sx={{ paddingY: 0 }} onClick={onClickViewTerms}>
              <img src="/images/help.svg" alt="help" />
            </IconButton>
          </Stack>
        </Stack>
      </Stack>
      <Stack
        bgcolor={'#F5FBFE'}
        padding={3}
        color="black"
        borderRadius={2}
        border={'1px solid #99DBFF'}
        alignItems="center"
        display="grid"
        sx={{ gridRowGap: 16 }}
      >
        <Stack direction="row" alignItems="center">
          <Stack width={'25px'} marginRight={2}>
            <img src="/images/phone.svg" alt="phone" width="15px" />
          </Stack>
          <LocalizationTypography
            variant="body1"
            fontWeight={400}
            color="#586069"
            marginRight="8px"
            textId="app.rentalPage.welcomeBack.text.phoneNumber"
            defaultText="Phone number"
          />
          <Typography variant="body1" fontWeight={500} color="primary.main">
            {formatNumber(cachedPhoneNumber?.replace(/\D/g, ''))}
          </Typography>
        </Stack>
        <Stack direction="row" alignItems="center">
          <Stack width={'25px'} marginRight={2}>
            <img src="/images/credit_score.svg" alt="phone" width={'19px'} />
          </Stack>
          <LocalizationTypography
            variant="body1"
            fontWeight={400}
            color="#586069"
            marginRight="8px"
            textId="app.rentalPage.welcomeBack.text.cardEndingIn"
            defaultText="Card ending in"
          />
          <Typography variant="body1" fontWeight={500} color="primary.main">
            {paymentInfo?.cardLast4}
          </Typography>
          <Button
            sx={{ p: 0 }}
            variant="text"
            onClick={handleEditPaymentMethod}
          >
            <LocalizationTypography
              variant="body2"
              sx={{ textDecoration: 'underline' }}
              textId="button.edit"
              defaultText="Edit"
            />
          </Button>
        </Stack>
      </Stack>
      <Stack>
        <Stack display="flex" flexDirection="row">
          <TextField
            error={!!rewardRedeemError}
            variant="outlined"
            placeholder={lt('placeholder.promoInput', 'Promo Code')}
            onChange={onChangePromoteCode}
            sx={{
              '& .MuiInputBase-root': {
                height: '100%',
                border: INPUT_BORDER_STYLES.DEFAULT,
                borderTopLeftRadius: '8px',
                borderBottomLeftRadius: '8px',
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
              },
              '& input::placeholder': {
                color: '#6A737D',
                fontWeight: 400,
                fontSize: '1rem',
                fontFamily: 'Helvetica Neue',
              },
              '& .Mui-focused': {
                border: INPUT_BORDER_STYLES.FOCUSED,
              },
              '& .Mui-error': {
                border: INPUT_BORDER_STYLES.ERROR,
              },
              flex: 1,
            }}
            InputProps={{
              sx: {
                '& .MuiOutlinedInput-notchedOutline': {
                  border: 'none !important',
                  boxShadow: 'none !important',
                },
              },
              endAdornment: renderEndAdornmentPromotionField && (
                <InputAdornment position="end">
                  {renderEndAdornmentPromotionField}
                </InputAdornment>
              ),
            }}
          />
          <MainButton
            sx={{
              border: 'unset',
              height: '100%',
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              borderTopRightRadius: '8px',
              borderBottomRightRadius: '8px',
            }}
            variant="contained"
            onClick={redeemReward}
            disabled={
              isRedeemRewardLoading ||
              !promoteCode ||
              !!rewardRedeemSuccessMessage
            }
          >
            {isRedeemRewardLoading ? (
              <CircularProgress size="16px" color="inherit" />
            ) : (
              lt('text.apply', 'Apply')
            )}
          </MainButton>
        </Stack>

        {rewardRedeemError && (
          <Typography
            variant="body2"
            fontWeight={500}
            color="error.main"
            marginTop={0.5}
          >
            {rewardRedeemError}
          </Typography>
        )}

        {rewardRedeemSuccessMessage && (
          <Typography
            variant="body2"
            fontWeight={500}
            color="success.main"
            marginTop={0.5}
          >
            {rewardRedeemSuccessMessage}
          </Typography>
        )}
      </Stack>

      <Stack sx={{ display: 'grid', gridRowGap: 16 }}>
        <MainButton
          variant="contained"
          disabled={
            (!cachedPhoneNumber || !paymentInfo || !searchParams.get('box')) ??
            (requestBatteryMutation.isLoading ||
              rentalQuery?.isFetching ||
              isFetchingRental)
          }
          onClick={handleEjectBattery}
          isLoading={
            requestBatteryMutation.isLoading ||
            rentalQuery?.isFetching ||
            isFetchingRental
          }
        >
          <LocalizationTypography
            variant="body1"
            fontWeight={500}
            textId="app.rentalPage.welcomeBack.buttonEjectBattery"
            defaultText="Eject battery"
          />
        </MainButton>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent={'space-between'}
        >
          <Box width={'40%'} height={'1px'} bgcolor="#E1E4E8"></Box>
          <LocalizationTypography
            variant="body2"
            fontWeight={400}
            color="#6A737D"
            textId="text.or"
            defaultText="OR"
          />
          <Box width={'40%'} height={'1px'} bgcolor="#E1E4E8"></Box>
        </Stack>
        <MainButton
          variant="outlined"
          startIcon={<Add />}
          onClick={handleOpenApp}
        >
          <LocalizationTypography
            variant="body1"
            fontWeight={500}
            textId="app.homePage.buttonDownloadApp"
            defaultText="Download chargeFUZE app"
          />
        </MainButton>
      </Stack>
    </Stack>
  );
};
