import { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import {
  ALREADY_REDEEM_REWARD_CODE,
  REDEEM_REWARD_INVALID_CODE,
} from '~common';
import { useLanguageContext } from '~providers';
import { postRequest } from '~services';

export const useRedeemReward = () => {
  const [promoteCode, setPromoteCode] = useState('');
  const [rewardRedeemSuccessMessage, setRewardRedeemSuccessMessage] = useState<
    string | null
  >(null);
  const [rewardRedeemError, setRewardRedeemError] = useState<string | null>(
    null,
  );

  const { lt } = useLanguageContext();

  const redeemRewardMutation = useMutation({
    mutationFn: (promoCode: { code: string }) => {
      return postRequest({
        path: 'api/reward/redeem',
        data: promoCode,
      });
    },
    onError(error) {
      if (error instanceof AxiosError) {
        setRewardRedeemError(getMutationErrorMsg(error.response?.data));
      }
    },
    onSuccess(res) {
      setRewardRedeemSuccessMessage(res.data?.message);
    },
  });

  const getMutationErrorMsg = (error?: {
    code?: number;
    message?: string;
  }): string => {
    if (!error?.code || !error.message)
      return lt(
        'text.hooks.useRedeemReward.mutationError',
        'Something went wrong when redeeming the reward.',
      );

    const errorCode = error.code;

    switch (true) {
      case Object.values(REDEEM_REWARD_INVALID_CODE).includes(errorCode):
        return lt('text.hooks.useRedeemReward.invalidCode', 'Invalid code.');
      case Object.values(ALREADY_REDEEM_REWARD_CODE).includes(errorCode):
        return lt(
          'text.hooks.useRedeemReward.alreadyRedeem',
          'You’ve already redeemed this promo.',
        );
      default:
        return error.message;
    }
  };

  const onChangePromoteCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPromoteCode(e.target.value.trim());
    if (rewardRedeemError) setRewardRedeemError(null);
    if (rewardRedeemSuccessMessage) setRewardRedeemSuccessMessage(null);
  };
  const redeemReward = async () => {
    if (!promoteCode) return;

    try {
      setRewardRedeemError(null);
      setRewardRedeemSuccessMessage(null);
      redeemRewardMutation.mutate({
        code: promoteCode,
      });
    } catch (error) {
      const unknownMessage = lt(
        'text.hooks.useRedeemReward.unknownError',
        'An unknown error occurred when redeeming the reward.',
      );
      setRewardRedeemError(unknownMessage);
    }
  };

  return {
    onChangePromoteCode,
    redeemReward,
    rewardRedeemSuccessMessage,
    rewardRedeemError,
    promoteCode,
    isRedeemRewardLoading: redeemRewardMutation.isLoading,
  };
};
